.error {
	color: #e4032e;
	background-color: #ffffff;
}

.infos {
	color: grey;
}

.detail {
	height: 40px;
}
.obligatoire {
	color: red;
	padding-left: 5px;
}

.p-datatable-globalfilter-container {
	float: right;
}

.btnSelected {
	visibility: visible;
}

.btnNotSelected {
	opacity: 0.5;
	background: #CCC;
}

.menuSelected {
	visibility: visible;
	font-weight: bold;
	background: #ebe8ee;
	
}

.activityMenu {
	width: 800px;
}
.dialogue {
	--height: auto;
	width: 50%;
}

.text-align-center {
	text-align: center !important;
}

.commonClass .p-button {
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	overflow: hidden;
	background-color: #e4032e;
	color: #ffffff;
	font-size: 1em;
	height: 2.143em;
	font-family: "Roboto", "Helvetica Neue", sans-serif;
	padding: 0 1em;
	border: 0 none;
	-moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0
		rgba(0, 0, 0, 0.16);
	-webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0
		rgba(0, 0, 0, 0.16);
	box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0
		rgba(0, 0, 0, 0.16);
	-moz-transition: background-color 0.3s;
	-o-transition: background-color 0.3s;
	-webkit-transition: background-color 0.3s;
	transition: background-color 0.3s;
}
.commonClass .p-button {
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	overflow: hidden;
	background-color: #e4032e;
	color: #ffffff;
	font-size: 1em;
	height: 2.143em;
	font-family: "Roboto", "Helvetica Neue", sans-serif;
	padding: 0 1em;
	border: 0 none;
	-moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0
		rgba(0, 0, 0, 0.16);
	-webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0
		rgba(0, 0, 0, 0.16);
	box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0
		rgba(0, 0, 0, 0.16);
	-moz-transition: background-color 0.3s;
	-o-transition: background-color 0.3s;
	-webkit-transition: background-color 0.3s;
	transition: background-color 0.3s;
}

body .p-button:enabled:not(:focus):not(.p-dataview-header .p-highlight):hover {
	background-color:  #e4032e;
  }
  body .p-button:focus {
	outline: 0 none;
	background-color:  #e4032e;
  }

.commonClass .p-progressbar {
	background-color: #ffffff;
	border: 1px solid #d8d8d8;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	height: 1.5em;
	padding: 0;
	overflow: hidden;
	border-color: #d8d8d8;
  }
  .commonClass .p-progressbar .p-progressbar-value {
	background-color:  #e4032e;
	color: #ffffff;
	border: 1px solid  #e4032e;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	height: 1.5em;
	border: 1px solid  #e4032e;
  }
  .commonClass .p-progressbar .p-progressbar-value .pi {
	color: #ffffff;
  }
  .commonClass .p-progressbar .p-progressbar-label {
	color: #ffffff;
	display: none !important;
  }
  .commonClass .p-progressbar-indeterminate .p-progressbar-value {
	background-color: #9fa8da;
  }
  .commonClass .p-progressbar-indeterminate .p-progressbar-value::before {
	background-color:  #e4032e;
  }

  .commonClass .p-multiselect-panel .p-multiselect-header {
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	background-color: #e4032e;
	color: #ffffff;
	border: 1px solid #e4032e;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	margin: 0;
	border: 0 none;
	padding: 0.714em 1em;
	position: relative;
  }
@media only screen and (min-device-width: 576px) and (max-device-width: 767.98px) {
	.commonClass .title {
		font-size: 10px;
	}
	.commonClass .item {
		display: inline-block;
		vertical-align: middle;
		padding: 5px;
	}
	.commonClass .btnPosition {
		padding-top: 1px;
		padding-left: 10px;
	}
	.commonClass .p-button {
		-moz-border-radius: 3px;
		-webkit-border-radius: 3px;
		border-radius: 3px;
		overflow: hidden;
		background-color: #e4032e;
		color: #ffffff;
		font-size: 1em;
		height: 2.143em;
		font-family: "Roboto", "Helvetica Neue", sans-serif;
		padding: 0 1em;
		border: 0 none;
		-moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0
			rgba(0, 0, 0, 0.16);
		-webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0
			rgba(0, 0, 0, 0.16);
		box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0
			rgba(0, 0, 0, 0.16);
		-moz-transition: background-color 0.3s;
		-o-transition: background-color 0.3s;
		-webkit-transition: background-color 0.3s;
		transition: background-color 0.3s;
	}
	.commonClass .p-button {
		-moz-border-radius: 3px;
		-webkit-border-radius: 3px;
		border-radius: 3px;
		overflow: hidden;
		background-color: #e4032e;
		color: #ffffff;
		font-size: 1em;
		height: 2.143em;
		font-family: "Roboto", "Helvetica Neue", sans-serif;
		padding: 0 1em;
		border: 0 none;
		-moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0
			rgba(0, 0, 0, 0.16);
		-webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0
			rgba(0, 0, 0, 0.16);
		box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0
			rgba(0, 0, 0, 0.16);
		-moz-transition: background-color 0.3s;
		-o-transition: background-color 0.3s;
		-webkit-transition: background-color 0.3s;
		transition: background-color 0.3s;
	}
	.commonClass .p-button:enabled:not (:focus ):not (.p-dataview-header .p-highlight):hover {
		background-color: #e4032e;
	}
	.commonClass .p-button:focus {
		outline: 0 none;
		background-color: #e4032e;
	}
	.commonClass .p-dialog {
		border-radius: 3px;
		background-color: #ffffff;
		border: 1px solid #d8d8d8;
	}
	.commonClass .p-dialog .p-dialog-titlebar {
		-moz-border-radius-topleft: 3px;
		-webkit-border-top-left-radius: 3px;
		border-top-left-radius: 3px;
		-moz-border-radius-topright: 3px;
		-webkit-border-top-right-radius: 3px;
		border-top-right-radius: 3px;
		background-color: solid #f7f7f7;
		color: #212121;
		padding: 0.714em 1em;
	}
	.commonClass .p-dialog {
		-moz-border-radius: 3px;
		-webkit-border-radius: 3px;
		border-radius: 3px;
		background-color: white;
		border: 1px solid #d8d8d8;
	}
	.commonClass .p-dialog .p-dialog-titlebar .p-dialog-title {
		font-weight: 500;
		font-size: 20px;
		line-height: 32px;
		margin: 0;
	}
	.commonClass .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon {
		padding: 0;
		height: 1.65em;
		width: 1.65em;
		text-align: center;
		margin-top: 0.5em;
		-moz-border-radius: 50%;
		-webkit-border-radius: 50%;
		border-radius: 50%;
	}
	.commonClass .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon:hover,
		.commonClass .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon:focus {
		background-color: #e8e8e8;
		color: #000000;
	}
	.commonClass .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon .pi {
		color: #757575;
		display: inline-block;
	}
	.commonClass .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon .pi-window-maximize {
		font-family: "Material Icons";
		font-weight: normal;
		font-style: normal;
		font-size: 1.5em;
		/* Preferred icon size */
		display: inline-block;
		width: 1em;
		height: 1em;
		line-height: 1;
		text-transform: none;
		letter-spacing: normal;
		word-wrap: normal;
		white-space: nowrap;
		direction: ltr;
		text-indent: 0;
		/* Support for all WebKit browsers. */
		-webkit-font-smoothing: antialiased;
		/* Support for Safari and Chrome. */
		text-rendering: optimizeLegibility;
		/* Support for Firefox. */
		-moz-osx-font-smoothing: grayscale;
		/* Support for IE. */
		font-feature-settings: "liga";
	}
	.commonClass .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon .pi-window-maximize:before {
		content: "fullscreen";
	}
	.commonClass .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon .pi-window-minimize {
		font-family: "Material Icons";
		font-weight: normal;
		font-style: normal;
		font-size: 1.5em;
		/* Preferred icon size */
		display: inline-block;
		width: 1em;
		height: 1em;
		line-height: 1;
		text-transform: none;
		letter-spacing: normal;
		word-wrap: normal;
		white-space: nowrap;
		direction: ltr;
		text-indent: 0;
		/* Support for all WebKit browsers. */
		-webkit-font-smoothing: antialiased;
		/* Support for Safari and Chrome. */
		text-rendering: optimizeLegibility;
		/* Support for Firefox. */
		-moz-osx-font-smoothing: grayscale;
		/* Support for IE. */
		font-feature-settings: "liga";
	}
	.commonClass .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon .pi-window-minimize:before {
		content: "fullscreen_exit";
	}
	.commonClass .p-dialog .p-dialog-content {
		padding: 0.857em 1em;
	}
	.commonClass .p-dialog .p-dialog-footer {
		text-align: right;
		padding: 0.714em 1em;
		border: 0 none;
	}
	.commonClass .p-dialog .p-dialog-footer .p-button {
		width: auto;
		margin-right: 0.5em;
	}
	.commonClass .p-dialog .p-dialog-footer .p-button:last-child {
		margin-right: 0;
	}
}

@media only screen and (min-device-width: 768px) and (max-device-width: 5000px) {
	.commonClass .item {
		display: inline-block;
		vertical-align: middle;
		padding: 5px;
	}
	.commonClass .btnPosition {
		padding-top: 1px;
		padding-left: 10px;
	}
	.commonClass .p-button {
		-moz-border-radius: 3px;
		-webkit-border-radius: 3px;
		border-radius: 3px;
		overflow: hidden;
		background-color: #e4032e;
		color: #ffffff;
		font-size: 1em;
		height: 2.143em;
		font-family: "Roboto", "Helvetica Neue", sans-serif;
		padding: 0 1em;
		border: 0 none;
		-moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0
			rgba(0, 0, 0, 0.16);
		-webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0
			rgba(0, 0, 0, 0.16);
		box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0
			rgba(0, 0, 0, 0.16);
		-moz-transition: background-color 0.3s;
		-o-transition: background-color 0.3s;
		-webkit-transition: background-color 0.3s;
		transition: background-color 0.3s;
	}
	.commonClass .p-button {
		-moz-border-radius: 3px;
		-webkit-border-radius: 3px;
		border-radius: 3px;
		overflow: hidden;
		background-color: #e4032e;
		color: #ffffff;
		font-size: 1em;
		height: 2.143em;
		font-family: "Roboto", "Helvetica Neue", sans-serif;
		padding: 0 1em;
		border: 0 none;
		-moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0
			rgba(0, 0, 0, 0.16);
		-webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0
			rgba(0, 0, 0, 0.16);
		box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0
			rgba(0, 0, 0, 0.16);
		-moz-transition: background-color 0.3s;
		-o-transition: background-color 0.3s;
		-webkit-transition: background-color 0.3s;
		transition: background-color 0.3s;
	}
	.commonClass .p-button:enabled:not (:focus ):not (.p-dataview-header .p-highlight
		 ):hover {
		background-color: #e4032e;
	}
	.commonClass .p-button:focus {
		outline: 0 none;
		background-color: #e4032e;
	}
	.commonClass .p-dialog {
		border-radius: 3px;
		background-color: #ffffff;
		border: 1px solid #d8d8d8;
	}
	.commonClass .p-dialog .p-dialog-titlebar {
		-moz-border-radius-topleft: 3px;
		-webkit-border-top-left-radius: 3px;
		border-top-left-radius: 3px;
		-moz-border-radius-topright: 3px;
		-webkit-border-top-right-radius: 3px;
		border-top-right-radius: 3px;
		background-color: solid #f7f7f7;
		color: #212121;
		padding: 0.714em 1em;
	}
	.commonClass .p-dialog {
		-moz-border-radius: 3px;
		-webkit-border-radius: 3px;
		border-radius: 3px;
		background-color: white;
		border: 1px solid #d8d8d8;
	}
	.commonClass .p-dialog .p-dialog-titlebar .p-dialog-title {
		font-weight: 500;
		font-size: 20px;
		line-height: 32px;
		margin: 0;
	}
	.commonClass .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon {
		padding: 0;
		height: 1.65em;
		width: 1.65em;
		text-align: center;
		margin-top: 0.5em;
		-moz-border-radius: 50%;
		-webkit-border-radius: 50%;
		border-radius: 50%;
	}
	.commonClass .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon:hover,
		.commonClass .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon:focus
		{
		background-color: #e8e8e8;
		color: #000000;
	}
	.commonClass .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon .pi {
		color: #757575;
		display: inline-block;
	}
	.commonClass .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon .pi-window-maximize
		{
		font-family: "Material Icons";
		font-weight: normal;
		font-style: normal;
		font-size: 1.5em;
		/* Preferred icon size */
		display: inline-block;
		width: 1em;
		height: 1em;
		line-height: 1;
		text-transform: none;
		letter-spacing: normal;
		word-wrap: normal;
		white-space: nowrap;
		direction: ltr;
		text-indent: 0;
		/* Support for all WebKit browsers. */
		-webkit-font-smoothing: antialiased;
		/* Support for Safari and Chrome. */
		text-rendering: optimizeLegibility;
		/* Support for Firefox. */
		-moz-osx-font-smoothing: grayscale;
		/* Support for IE. */
		font-feature-settings: "liga";
	}
	.commonClass .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon .pi-window-maximize:before
		{
		content: "fullscreen";
	}
	.commonClass .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon .pi-window-minimize
		{
		font-family: "Material Icons";
		font-weight: normal;
		font-style: normal;
		font-size: 1.5em;
		/* Preferred icon size */
		display: inline-block;
		width: 1em;
		height: 1em;
		line-height: 1;
		text-transform: none;
		letter-spacing: normal;
		word-wrap: normal;
		white-space: nowrap;
		direction: ltr;
		text-indent: 0;
		/* Support for all WebKit browsers. */
		-webkit-font-smoothing: antialiased;
		/* Support for Safari and Chrome. */
		text-rendering: optimizeLegibility;
		/* Support for Firefox. */
		-moz-osx-font-smoothing: grayscale;
		/* Support for IE. */
		font-feature-settings: "liga";
	}
	.commonClass .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon .pi-window-minimize:before
		{
		content: "fullscreen_exit";
	}
	.commonClass .p-dialog .p-dialog-content {
		padding: 0.857em 1em;
	}
	.commonClass .p-dialog .p-dialog-footer {
		text-align: right;
		padding: 0.714em 1em;
		border: 0 none;
	}
	.commonClass .p-dialog .p-dialog-footer .p-button {
		width: auto;
		margin-right: 0.5em;
	}
	.commonClass .p-dialog .p-dialog-footer .p-button:last-child {
		margin-right: 0;
	}
}

.commonClass .p-checkbox .p-checkbox-box.p-highlight {
	border-color: #e4032e;
	background-color: #e4032e;
}

.commonClass .p-checkbox .p-checkbox-box.p-focus {
	border-color: #e4032e;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0
		rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
	-webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0
		rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
	-moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0
		rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
	-moz-transition: box-shadow 0.3s, background-color 0.3s;
	-o-transition: box-shadow 0.3s, background-color 0.3s;
	-webkit-transition: box-shadow 0.3s, background-color 0.3s;
	transition: box-shadow 0.3s, background-color 0.3s;
}

.p-component:disabled, .p-disabled {
	opacity: 1 !important;
	color: #9e9e9e;
	filter: Alpha(Opacity = 35) !important;
	background-image: none !important;
	cursor: auto !important;
}

.inputClass::placeholder {
	color: #777777 !important;
}

.inputClass#description { 
	min-width: 70%;
}

.commonClass .p-autocomplete-panel {
	background-color: #ffffff;
	border: 1px solid #d8d8d8;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	padding: 0;
	border: 0 none;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	-moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  .commonClass .p-autocomplete-panel .p-autocomplete-list {
	padding: 0.5em 0;
  }
  .commonClass .p-autocomplete-panel .p-autocomplete-list .p-autocomplete-list-item {
	padding: 0.571em 0.857em;
	color: #212121;
	margin: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
  }
  .commonClass .p-autocomplete-panel .p-autocomplete-list .p-autocomplete-list-item .p-autocomplete-query {
	font-weight: 700;
  }
  .commonClass .p-autocomplete-panel .p-autocomplete-list .p-autocomplete-list-item:hover, .commonClass .p-autocomplete-panel .p-autocomplete-list .p-autocomplete-list-item.p-highlight {
	background-color: #E91E63;
	color: #ffffff;
  }
  .commonClass .p-autocomplete-panel .p-autocomplete-list .p-autocomplete-group {
	padding: 0.571em 0.857em;
  }
  .commonClass .p-autocomplete .p-autocomplete-loader {
	font-family: "Material Icons";
	font-weight: normal;
	font-style: normal;
	font-size: 1.5em;
	/* Preferred icon size */
	display: inline-block;
	width: 1em;
	height: 1em;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	text-indent: 0;
	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;
	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;
	/* Support for IE. */
	font-feature-settings: "liga";
	-webkit-animation-delay: 0;
	-webkit-animation-duration: 1000ms;
	-webkit-animation-name: spin;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	-moz-animation-delay: 0;
	-moz-animation-duration: 1000ms;
	-moz-animation-name: spin;
	-moz-animation-iteration-count: infinite;
	-moz-animation-timing-function: linear;
	animation-delay: 0;
	animation-duration: 1000ms;
	animation-name: spin;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	color: #757575;
	right: 0;
  }
  .commonClass .p-autocomplete .p-autocomplete-loader:before {
	content: "refresh";
  }
  .commonClass .p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
	right: 1em;
  }
  .commonClass .p-autocomplete .p-autocomplete-dropdown {
	background-color: transparent;
	box-shadow: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	height: 1.429em;
	width: 1.429em;
	padding: 0;
	right: 0;
	bottom: 0;
	margin-right: 0;
	position: absolute;
  }
  .commonClass .p-autocomplete .p-autocomplete-dropdown .p-button-text {
	display: none;
  }
  .commonClass .p-autocomplete .p-autocomplete-dropdown .pi {
	font-size: 1.5em;
	color: #757575;
  }
  .commonClass .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container.p-inputtext {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 2px 2px 1px 2px;
  }
  .commonClass .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container.p-disabled {
	border-bottom: 1px dotted;
  }
  .commonClass .p-autocomplete.p-autocomplete-multiple .p-autocomplete-input-token {
	float: none;
	display: inline-block;
	margin: 0 1px;
	vertical-align: middle;
  }
  .commonClass .p-autocomplete.p-autocomplete-multiple .p-autocomplete-input-token > input {
	padding: 0;
	margin: 0;
	font-size: 1em;
  }
  .commonClass .p-autocomplete.p-autocomplete-multiple .p-autocomplete-token {
	display: inline-block;
	float: none;
	vertical-align: middle;
	font-size: 1em;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
  }
  .commonClass .p-autocomplete.p-autocomplete-multiple .p-autocomplete-token .p-autocomplete-token-label {
	display: inline-block;
	vertical-align: middle;
  }
  .commonClass .p-autocomplete.p-autocomplete-multiple .p-autocomplete-dropdown.p-button.p-button-icon-only {
	bottom: 3px;
  }
  .commonClass .p-autocomplete.p-autocomplete-multiple .p-autocomplete-loader {
	margin-top: -0.35em;
  }
  .commonClass .p-fluid .p-autocomplete.p-autocomplete-dd > .p-inputtext {
	width: 100%;
  }
  .commonClass .p-dropdown {
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	border-width: 0;
	background: transparent no-repeat;
	background-image: linear-gradient(to bottom, #3F51B5, #3F51B5), linear-gradient(to bottom, #bdbdbd, #bdbdbd);
	background-size: 0 2px, 100% 1px;
	background-position: 50% 100%, 50% 100%;
	transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
	padding-bottom: 2px;
	box-sizing: border-box;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
  }

  .commonClass .p-checkbox-box.p-highlight.p-disabled {
	background-color: grey;
	border-color: grey;
  }

  .commonClass label.p-disabled {
	color: grey;
  }
  