.message-dialog {
    width: 30%;
}
.error-message .p-dialog-titlebar, .error-message .p-button {
    background-color: #e4032e !important;
    color: #ffffff !important;
}
.warn-message .p-dialog-titlebar, .warn-message .p-button {
    background-color:#f49600 !important;
    color: #ffffff !important;
}
.info-message .p-dialog-titlebar, .confirm-message .p-dialog-titlebar, .info-message .p-button, .confirm-message .p-button {
    background-color: #164194 !important;
    color: #ffffff !important;
}

.message-dialog .p-dialog-footer {
    text-align: center !important;
}
.message-dialog h3 {
    margin-top: 0;
}

.message-dialog .alarm {
    width: 50%;
}
