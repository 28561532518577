
.wash-topbarinfo-div-title-icon{ 
 /*! background-color: #ffffff; */
 box-shadow: inset 0 -2px 4px 0 rgba(240, 7, 7, 0.14);

 -moz-box-shadow: inset 0 -2px 4px 0 rgba(0, 0, 0, 0.14);

 display: table-cell;
vertical-align: middle;
background-color: red;
float : left;

padding: 0px 20px 0 0;
max-height: 42px;
}
#rightpanel-menu-button i {
  font-size: 24px !important;
  height: 0px !important;
}
.wash-topbar, #rightpanel-menu-button {
    background:white!important;
    color: black !important;
    /*margin-left:75px;*/
    background-color:white;  
}
.noHover{
  pointer-events: none;
}
.hyphens {
  width: 15em;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}


.mobileShow {
  display: none;
}

.errorMessageShow {
  color: red;
  display: inline;
}
.errorMessageHide
{
  display: none;
}

/* Smartphone Portrait and Landscape */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .mobileShow {
    display: inline;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .mobileHide {
    display: none;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .fontSizeMobile{
    font-size:8px;
    margin-Top : 7px  !important;
    }
  }
@media only screen and (min-device-width: 480px) and (max-device-width: 1500px) {
.fontSizeIpadeLaptope{
  font-size:12px;
  }
}

@media only screen and (min-device-width:  320px) and (max-device-width: 480px) {
.logoSizeMobile{
    background-size : 100px 18px !important;;

    width: 100px !important;
    height: 18px !important;

    }
}
@media only screen and (min-device-width:  0px) and (max-device-width: 320px) {
.logoSizeMobile{
      background-size : 70px 18px !important;;

      width: 70px !important;
      height: 18px !important;

}
}
@media only screen and (min-device-width: 0px) and (max-device-width: 320px) {
.fontSizeMobile{
  font-size:8px;
  margin-Top : 8px  !important;
  }
}
#root {
  height: 100%;
  display: grid;
}
.layout-wrapper {
  display: flex;
  flex-direction: column;
}
#spacer {
  flex-grow: 1;
}
#layout-footer {
  width: auto;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  background: white !important;
  color: black !important;
  text-align: center;
  padding-left: 60px !important;
  padding-right: 60px !important;
  flex-direction: row;
  margin-top: -30px;
 }
.centered {
   position: fixed;
   left: 50%;
   transform: translate(-50%, 0);
}
.visibilityHidden {
  display: none;
  width : 0%
}

@media only screen and (min-device-width: 642px) and (max-device-width: 1200px) {
  .table-size-screen-642-to-1366 {
    min-width: 100%  !important;
  }
  .layout-breadcrumb-size-screen-642-to-1366 {
      min-width: 1285px    !important;
  }
}

.ulhorizontal
{
  list-style-type:none;

}

.ulhorizontal li
{
  float: right;
  margin-right: 8px;
  
}
.tooltip {
  position: relative;
  
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}


.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptextDataTable {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}


.tooltip:hover .tooltiptextDataTable {
  visibility: visible;
  display: block;
  position: absolute;
  background-color: #808080;
  border: 1px solid #CCC;
  border-radius: 6px;
  border-bottom: 1px dotted black;
  color:#fff;
  z-index: 1;
  margin: -45px 0px 0px -3px;
}

.p-ellipsis {
 
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
}
.hasTooltip span {
  display: none;
  color: #000;
  text-decoration: none;
  padding: 3px;
  
  
}

.hasTooltip:hover span {
 
  display: block;
  position: absolute;
  background-color: #808080;
  border: 1px solid #CCC;
  border-radius: 6px;
  border-bottom: 1px dotted black;
  color:#fff;
  z-index: 1;
  margin: -45px 0px 0px -3px;
}

body .tableData .p-datatable .p-sortable-column {
  color: #212121;
}
body .tableData .p-datatable .p-sortable-column .p-sortable-column-icon {
  vertical-align: middle;
  color: #757575;
  margin: 0 0 0 4px;
}
body .tableData .p-datatable .p-sortable-column:not(.p-highlight):hover {
  background-color: #e8e8e8;
  color: #000000;
}
body .tableData .p-datatable .p-sortable-column.p-highlight {
  background-color: #E91E63;
  color: #ffffff;
  border-top-color: #E91E63;
}
body .tableData .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #ffffff;
}
body .tableData .p-datatable .p-sortable-column .p-column-title {
  vertical-align: middle;
}

.pi .pi-sort {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}
.pi .pi-sort:before {
  content: "sort";
}
.pi .pi-sort-up {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}
.pi .pi-sort-up:before {
  content: "keyboard_arrow_up";
}
.pi .pi-sort-down {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}
.pi .pi-sort-down:before {
  content: "keyboard_arrow_down";
}


body .tableData .p-datatable .p-datatable-tbody > tr > td {
  background: inherit;
  padding: 0.714em 0.857em;
  border-right:1px solid gray;
}
body .table-first-checkbox-column .tableData .p-datatable .p-datatable-tbody > tr > td:first-child {
  border:0;
}
body .table-first-checkbox-column .tableData .p-datatable .p-datatable-tbody > tr > td:first-child,
body .tableData .p-datatable .p-datatable-tbody > tr > td:last-child {
  border:0;
}

body .tableData .p-datatable .p-datatable-thead > tr > th {
  padding: 0.857em;
  border-right:1px solid gray;
  text-align: left;
  font-weight: 500;
  border-left: 1px solid #d8d8d8;
  background-color:#ffffff;
  height: 5px;
  font-weight:bold;
}
body .table-first-checkbox-column .tableData .p-datatable .p-datatable-thead > tr > th {
  border-left: 0;
}
body .table-first-checkbox-column .tableData .p-datatable .p-datatable-thead > tr > th:first-child {
  border-right:0;
  border-left: 1px solid #d8d8d8;
}
body .table-first-checkbox-column .tableData .p-datatable .p-datatable-thead > tr > th:first-child,
body .tableData .p-datatable .p-datatable-thead > tr > th:last-child {
  border-color: #d8d8d8;
}
body .tableData .p-datatable .p-datatable-header,
body  .tableData .p-datatable .p-datatable-footer {

  background-color: #e4032e;
  color: #ffffff;
  border-color: #e4032e;
  padding: 0.714em 1em;
  text-align: center;
  font-weight: normal;
  height: 50px;
  
}

body .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):not(.p-highlight-contextmenu):hover {
  cursor: pointer;
  color: #000000;
}

body .tableData .p-paginator {
							
  color: #ffffff;
  border: #F49600;
  background-color: #F49600;
  padding: 0.714em 1em;
}

.tableData td, .tableData th {
    text-overflow: ellipsis;
    overflow: hidden;
}

.p-button-refrech {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  overflow: hidden;
  background-color: gray;
  color: #ffffff;
  font-size: 1em;
  height: 2.143em;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  padding: 0 1em;
  border: 0 none;
  -moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

body .p-datatable .p-datatable-tbody > tr {
  background: #ffffff;
  outline: none !important;
}
body .p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background-color: #f4f4f4;
}
body .tableData .p-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background-color: #f4f4f4;
  color: black;
}
body .tableData .p-datatable .p-datatable-tbody > tr.p-highlight {
  background-color: rgba(226, 221, 231, 0.44313725490196076);
  color: black;
  border: 2px solid #F49600 !important;
}
body .p-datepicker .p-datepicker-header {
  background-color: #e2bbc5;
  color: #ffffff;
  border: 1px solid #e4032e;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 0.714em 1em;
  background: #e4032e;
  border-color:  #e4032e;
  -moz-border-radius-topleft: 2px;
  -webkit-border-top-left-radius: 2px;
  border-top-left-radius: 2px;
  -moz-border-radius-topright: 2px;
  -webkit-border-top-right-radius: 2px;
  border-top-right-radius: 2px;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
}

body .p-datepicker table thead tr {
    color: #ffffff;
    background: #e4032e;
}
body .p-datepicker table td.p-datepicker-today > span.p-highlight {
  background-color: #e4032e;
  color: #ffffff;
}
.ulhorizontal .reloadButton{
  height:1.9em !important;
  width:1.9em !important; 
  background-color: gray !important;
  border-radius: 50% !important;
}
body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color:  #e4032e !important;
  color: #ffffff;
  outline: none !important;
  outline-style:none;
}

body .p-inputtext {
  background: transparent no-repeat;
  background-image: linear-gradient(to bottom, #e4032e, #e4032e), linear-gradient(to bottom, #bdbdbd, #bdbdbd);
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-width: 0;
  font-size: 1em;
  padding: 2px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

.layout-container .layout-menu .profile {
  background: none !important;
}

.layout-container .layout-main .layout-content {
  padding: 23px;
  padding-top: 60px;
}


.topBar{
  position: fixed;
  z-index: 80;
  width: 100%;
  background-color: #F49600 !important; 
  color: white; 
  height: 46px; 
  overflow: hidden; 
}
.layout-container .layout-breadcrumb ul li {
  color: white !important;
 /* margin: -5px 0 0 0 !important;*/
  margin-top: -5px !important;
  padding: 0 0 0 2px !important;
  margin-right: 5px !important;
  
  list-style: none ;
  display: inline-block;
}
.layout-container .layout-breadcrumb ul li span{
   font-weight: bold !important; 
}
.layout-container .layout-breadcrumb ul {
   margin: -2px 0 0 0 !important; ; 
  padding: 0 0 0 0px !important; ;
  list-style: none !important; ;
  color: #757575 !important;
  display: inline-block !important;
}
.titleFilterClass {
  color: white;
  text-transform:none;
  margin-right: 5px !important;
  
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white !important;
  opacity: 1!important; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:  white!important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color:  white !important;
}

.pointer {cursor: pointer;}

input[type="search"]::-webkit-search-cancel-button {
  display: none;
}
  
.text-align-center
{
 text-align: center;
}
.p-component:disabled,  .p-disabled {
  opacity: 0.8 !important;
  filter: Alpha(Opacity=35)!important;
  background-image: none!important;
  cursor: auto !important;
}
.p-link {
  outline: none !important;
}
.p-col-checkbox {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 0.5em !important;
  width: 2.5em;
}
body{
   outline: none !important;
}
.material-icons {
  margin-left: 10px;
}

.layout-container .ultima-menu li a {
  padding-right: 0 !important;
}

/* Extra small devices (portrait phones, less than 576px)*/
/* Small devices (landscape phones, 576px and up)*/
/* Medium devices (tablets, 768px and up)*/
@media only screen and (min-device-width: 0px) and (max-device-width: 991.98px) {
  .layout-container .topbar .logo {
    width: 100px;
    background-size: 100px 30px;
  }
  button.topbar-items  {
    display: inline-block !important;
    margin-top: -55px !important;
    width: 1em !important;
    height: 1em !important;
    border: none !important;
    background: transparent !important;
    padding: 0 !important;
    margin-right: 14px !important;
  }
  button.topbar-items .material-icons {
    margin: 0;
  }
}