.timeslot-btn .p-disabled {
    background-color: gray !important;
    opacity: 0.5 !important;
}

.timeslot-btn .p-button.p-button-icon-only {
    border-radius: 50%;
    width: 28px;
    height: 28px;
}

.contracts-view, .contracts-view .p-tabview, .contracts-view .p-tabview .p-tabview-panel {
    padding: 0 !important;
    margin: 0;
}

.contracts-card {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}