.text-center {
	text-align: center !important;
}
.activ-colone {
	width: 5.5% !important;
	text-align: center !important;
}

.obligatoire {
	color: red;
}

.test {
	background-color: #EFEFEF;
	height: 50px;
}

.access-code-gen .p-button {
    background-color: grey !important;
}

.access-code-gen .qrcode-mail {
	padding: 0;
	margin: 0;
}
.access-code-gen .info {
	font-style: italic;
}

.access-code-gen .connect-header h3{
	margin-top: 0.5em;
}

.access-code-gen .checkbox-enabled {
	margin-top: 0.3em;
}

.access-code-gen .reset-date {
	margin-top: 0.3em;
	text-align: right;
	color: black;
	font-style: italic;
}

.users .p-disabled .p-dropdown-label {
    color:black !important;
}