 /* Extra large devices (large desktops, 1200px and up)*/

 @media only screen and (min-device-width: 1200px) and (max-device-width: 1380px) {
    .activity .prest .prest-icon span {
        font-size: 3.5em;
        width: 5px;
        margin-left: -50px;
    
    }
    
    .prest-amount .title h2 {
        margin-bottom: -5px;
        font-size: 18px;
        font-weight: bold;
        margin-top: 10px;
    }
    
    .prest-amount .amount h1 {
        font-weight: bold;
        margin-top: 0px;
        font-size: 20px;
        margin-bottom: 0px;
    }
    .activity .vente .vente-icon span {
        font-size: 3.5em;
        width: 5px;
        margin-left: -50px;
    }
    
    .vente-amount .amount h1 {
        font-weight: bold;
        margin-top: 0px;
        font-size: 20px;
        margin-bottom: 0px;
    }
    
    .chart-title-vente
    {
        font-size: 13px;
        background-color: rgb(75, 153, 211);
        padding: 4px;
        color: white;
        margin-left: 0px !important;
    
    }
    
    
    .activity-chart {   
        color: #000000;
    }
    
    .p-smal-screen {
        width: 25% !important;                                
    }
    
    .p-activity-commerce {
       
        font-size: 15px;
        
        vertical-align: bottom;
        
      }
    
      .chart-title-prest
    {
        font-size: 13px;
        background-color: #00b752;
        padding: 4px;
        color: white;
        margin-left: 0px !important;
    
    }
    
    
    
      .detail-container{
        margin-top: 10px;
        padding: 0 !important;
        }
        
        .detail{
            margin-left: -15px;
            font-size: 17px;
            margin-left: 1px;
        }
        .legend{
            color:#696969;
            list-style: none;
            display: flex;
            flex-direction: row;
            font-size: 11px ;
            font-style:  'bold';
            padding-left: 24px !important;
            padding-top: 0 !important;
        }   
       
    
        .activityVente .containerLegend
        {
            margin-left: -25px;
            height: 80px;
            width: 246px;
            padding-top: 25px !important;
            padding-bottom: 0 !important;
        } 
        .activity-analysis .containerLegend
        {
           
            height: 100px;
            margin-left: -10px;
            
        } 
        .activityPrestation .containerLegend
        {
            margin-left: -25px;
            height: 80px;
            width: 246px;
            padding-top: 25px !important;
            padding-bottom: 0 !important;
        } 
        
        .activity-analysis  .analyse .containerLegend
        {
            
            height: 100px;
            margin-left: -16px;
              
        }
        .activity-analysis .content-header .cheader-label {
            margin-left: -20px;
            text-align: left;
            width: 240px;
        }
        .activity-analysis .content-header .cheader-value {
            margin-left: -27px;
            text-align: left;
            font-size: 22px;
            font-weight: bold;
        }
         
        .activity-analysis .activity-chart {   
            color: '#000000';
            margin-left: 0px;
        }   
    
        .activity-analysis  {
            border-style: solid;
            border-color: #dadeea;
        }
        
        .activity-analysis .analysis-header {
            margin: auto;
            padding: 0;
            width: 96%;
            border-bottom-style: solid;
            border-bottom-color: #dadeea;
        }
        
        .analysis-header .header-title h2{
            font-size: 25px;
            font-weight: bold;
            margin-left: -5px;
        }
        .analysis-header .header-label {
            font-size: 18px;
        }
        .analysis-header .header-value {
            font-size: 18px;
            color: #00b752;
        }
        
        .analysis-header .amount h1 {
            color: white;
            font-weight: bold;
            text-align: center;
            background-color:  rgb(75, 153, 211);
            padding: 5px;
        }
        
        .activity-analysis .content-header {
            margin: auto;
            width: 95%;
            font-size: 18px;
        }
        .content-header .cheader-label {
            text-align: center;
        }
        .content-header .cheader-value {
            text-align: center;
            font-size: 22px;
            font-weight: bold;
        }
        
        .content-header .average {
            color: white;
            background-color:  rgb(75, 153, 211);
            padding: 2px 10px;
        }
        
        .activity-analysis .prest .prest-icon {
            text-align: center;
            padding-top: 10px;
        }
        
        .activity-analysis .borne .borne-icon, .activity-analysis .gantry .gantry-icon {
            text-align: center;
            padding-top: 5px;
        }
        
        .activity-analysis .prest .prest-icon span {
            color: rgb(0, 183, 82);
            font-size: 5em;
            margin-left: -10px;
        }
        .activity-analysis .borne .borne-icon span {
            color: #e4032e;
            font-size: 5em;
            margin-left: -10px;
        }
        .activity-analysis .gantry .gantry-icon span {
            color: rgb(14, 65, 148);
            font-size: 5em;
            margin-left: -10px;
        }
        
        .activity-analysis .prest .prest-label {
            padding-top: 25px;
        }
        
        .activity-analysis .borne .borne-label, .activity-analysis .gantry .gantry-label {
            padding-top: 10px;
        }
        
        .activity-analysis .prest .prest-label h2, .activity-analysis .borne .borne-label h2,
         .activity-analysis .gantry .gantry-label h2 {
            font-size: 25px;
            font-weight: bold;
        }
        
        .activity-analysis .prest .prest-amount {
            text-align: right;
            padding-top: 35px;
        }
        
        .activity-analysis .borne .borne-amount, .activity-analysis .gantry .gantry-amount {
            text-align: right;
            padding-top: 20px;
        }
        
        .activity-analysis .prest-amount .amount h1{
            font-weight: bold;
            margin-top: 0px;
            margin-bottom: 0px;
            background-color: rgb(0, 183, 82);
        }
        
        .activity-analysis .borne-amount .amount h1{
            font-weight: bold;
            margin-top: 0px;
            margin-bottom: 0px;
            background-color: #e4032e;
        }
        
         .activity-analysis .gantry-amount .amount h1 {
            font-weight: bold;
            margin-top: 0px;
            margin-bottom: 0px;
            background-color: rgb(14, 65, 148);
        }
        
        .activity-analysis .prest-amount .amount{
            font-weight: bold;
            color: white;
            background-color: rgb(0, 183, 82);
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
        }
        
        .activity-analysis .borne-amount .amount {
            font-weight: bold;
            color: white;
            background-color: #e4032e;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
        }
        
        .activity-analysis .gantry-amount .amount {
            font-weight: bold;
            color: white;
            background-color: rgb(14, 65, 148);
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
        }
        
        .activity-analysis .vente .vente-icon {
            text-align: center;
            padding-top: 10px;
        }
        
        .activity-analysis .vente .vente-icon span {
            color: rgb(14, 65, 148);
            font-size: 5em;
            margin-left: -10px;
        }
        
        .activity-analysis .vente .vente-label {
            padding-top: 20px;
        }
        
        .activity-analysis .vente .vente-label h2 {
            font-size: 25px;
            font-weight: bold;
        }
        
        .activity-analysis .vente .vente-amount {
            text-align: right;
            padding-top: 35px;
        }
        
        .activity-analysis .vente-amount .amount h1 {
            font-weight: bold;
            margin-top: 0px;
            margin-bottom: 0px;
            background-color: rgb(14, 65, 148);
        }
        
        .activity-analysis .vente-amount .amount {
            font-weight: bold;
            color: white;
            background-color: rgb(14, 65, 148);
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
        }
        
        .activity-analysis-chart {
            color: #000000;
        }
        
        .content-header .cheader-label {
            margin-left: -38px;
        }
        .content-header .cheader-value {
            text-align: center;
            font-size: 22px;
            font-weight: bold;
            margin-left: -55px;
        }
        .dashboard .p-panel .p-panel-titlebar .p-panel-titlebar-icon {
            position: relative;
            width: 1.5em;
            height: 1.5em;
            line-height: 1.5em;
            text-align: center;
            color: #ffffff;
            margin: 0;
            -moz-transition: background-color 0.3s;
            -o-transition: background-color 0.3s;
            -webkit-transition: background-color 0.3s;
            transition: background-color 0.3s;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            background-color: grey;
           
          }
          .activityPrestation .p-panel .p-panel-titlebar .p-panel-titlebar-icon {
            margin-top: -98px;
        }
        .activityVente .p-panel .p-panel-titlebar .p-panel-titlebar-icon {
            margin-top: -98px;
        }
        .activity .p-panel-titlebar .pi.pi-minus {
            font-size: 0.9em; 
            display: inline-block;
            width: 1em;
            height: 1em;
            line-height: 1.58;
            text-transform: none;
            letter-spacing: normal;
            word-wrap: normal;
            white-space: nowrap;
            direction: ltr;
            text-indent: 0;
            -webkit-font-smoothing: antialiased;
            text-rendering: optimizeLegibility;
            -moz-osx-font-smoothing: grayscale;
            font-feature-settings: "liga";
        }
        .activity .p-panel-titlebar .pi.pi-plus {
            
            font-size: 0.9em; 
            display: inline-block;
            width: 1em;
            height: 1em;
            line-height: 1.58;
            text-transform: none;
            letter-spacing: normal;
            word-wrap: normal;
            white-space: nowrap;
            direction: ltr;
            text-indent: 0;
            -webkit-font-smoothing: antialiased;
            text-rendering: optimizeLegibility;
            -moz-osx-font-smoothing: grayscale;
            font-feature-settings: "liga";
        }
        .vente-icon .imageActivity {
            width: 45px;
        }
        .p-sm-1, .p-sm-2, .p-sm-3, .p-sm-4, .p-sm-5, .p-sm-6, .p-sm-7, .p-sm-8, .p-sm-9, 
        .p-sm-10, .p-sm-11, .p-sm-12, .p-md-1, .p-md-2, .p-md-3, .p-md-4, .p-md-5, .p-md-6,
         .p-md-7, .p-md-8, .p-md-9, .p-md-10, .p-md-11, .p-md-12, .p-lg-1, .p-lg-2, .p-lg-3,
          .p-lg-4, .p-lg-5, .p-lg-6, .p-lg-7, .p-lg-8, .p-lg-9, .p-lg-10, .p-lg-11, .p-lg-12,
           .p-xl-1, .p-xl-2, .p-xl-3, .p-xl-4, .p-xl-5, .p-xl-6, .p-xl-7, .p-xl-8, .p-xl-9, .p-xl-10, .p-xl-11, .p-xl-12 {
            padding: 0.2em !important;
        }
    
        .maint-dashboard .p-smal-screen {
	        width: 33% !important;                                
	    }
	    
	    .maint-status .refresh .refresh-time .time {
	        margin-left: -15px;
	        font-size: 17px;
        }
}