@media only screen and (min-device-width: 768px) {
  .roleClass .p-picklist.p-picklist-responsive .p-picklist-source-wrapper {
    width: 33.3333% !important;
  }
  .roleClass .p-picklist.p-picklist-responsive .p-picklist-target-wrapper {
    width: 50% !important;
  }

  .roleClass .perm-header {
    padding: 0;
    margin: 0;
    visibility: visible !important;
    display: block !important;
  }

  .roleClass .perm-checkbox {
    text-align: center;
  }
  .roleClass .perm-checkbox .p-checkbox-label {
    visibility: hidden !important;
    display: none !important;
  }
  .roleClass .perm-head-checkbox .p-checkbox-label {
    visibility: visible !important;
    display: block !important;
    text-align: center;
  }

  .roleClass .perm-target div {
    padding: 0;
    margin: inherit !important;
    float: none !important; 
  }
}

.p-picklist.p-picklist-responsive {
  padding: 0;
}

.roleClass .perm-head-checkbox div {
  padding-bottom: 0;
  margin: 0;
}

.roleClass .perm-head-checkbox .p-checkbox-label {
  visibility: hidden;
  display: none;
}
.roleClass .perm-checkbox .p-checkbox-label {
  padding-left: 0;
  padding-right: 0;
  visibility: visible;
  display: block;
}
.roleClass .perm-header {
  visibility: hidden;
  display: none;
}
.roleClass .perm-source-header {
  background-color: rgb(228, 3, 46);
  color: #ffffff;
  border: 1px solid rgb(228, 3, 46);
  float: left;
}

.roleClass .perm-buttons-header {
  float: left;
}

.roleClass .perm-target-header {
  background-color: rgb(228, 3, 46);
  color: #ffffff;
  border: 1px solid rgb(228, 3, 46);
  padding: 0;
  margin: 0;
  float: right;
}

.roleClass .perm-target {
  padding-bottom: 0;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
}

.roleClass .perm-target div {
  padding: 0;
  margin-top: 2px;
  margin-bottom: 2px;
  float: left; 
}

.roleClass .p-picklist .p-picklist-caption {
    background-color: rgb(228, 3, 46);
    color: #ffffff;
    border: 1px solid rgb(228, 3, 46);
    padding: 0.714em 1em;
    width:100%;
  }
  .roleClass .p-picklist .p-picklist-caption .pi {
    color: #ffffff;
  }
  .roleClass .p-picklist .p-picklist-list {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 0;
  }
  .roleClass .p-picklist li.p-picklist-item {
    padding: 0.571em 0.857em;
    margin: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    color: #212121;
  }
  .roleClass .p-picklist li.p-picklist-item:not(.p-highlight):hover {
    background-color: #e8e8e8;
    color: #000000;
  }
  .roleClass .p-picklist li.p-picklist-item.p-highlight {
    background-color:#F49600;
    color: #ffffff;
  }
  .roleClass .p-picklist .p-picklist-buttons .p-button {
    margin: 0 auto 0.571em auto;
  }
  .roleClass .p-picklist .p-picklist-buttons .p-button.p-button-icon-only {
    width: 2.5em;
    height: 2.5em;
  }
  .roleClass .p-picklist .p-picklist-buttons-cell {
    text-align: center;
  }
  .roleClass .p-picklist.p-picklist-responsive .p-picklist-buttons {
    width: 3.429em;
  }
  .roleClass .p-picklist.p-picklist-responsive .p-picklist-buttons .p-button.p-button-icon-only {
    margin: 0 auto;
    display: block;
    margin-bottom: 0.571em;
  }
  .roleClass .p-picklist.p-picklist-responsive .p-picklist-list .p-picklist-item .p-checkbox {
    margin-right: 0.571em;
    vertical-align: top;
  }
  .roleClass .p-picklist.p-picklist-responsive .p-picklist-list .p-picklist-item .p-checkbox, .roleClass .p-picklist.p-picklist-responsive .p-picklist-list .p-picklist-item .p-checkbox * {
    box-sizing: content-box;
  }

  .roleClass .p-button:enabled:not(:focus):not(.p-dataview-header .p-highlight):hover {
    background-color: rgb(228, 3, 46);
  }
  .roleClass .p-button:focus {
    outline: 0 none;
    background-color: rgb(228, 3, 46);
  }

  .roleClass .sourceStyle {
    height: '300px'; 
    width:'550px' ;
  }

  .roleClass .targetStyle {
    height: '300px'; 
    width:'550px' ;
  }
  
  .roleClass .roles-title {
  	padding: 0;
	margin: 0;
  }