@media only screen and (min-device-width: 642px) and (max-device-width: 1200px) {
   
  .container-size-screen-642-to-1366 {
    min-width: 1250px   !important;
  }
  .layout-breadcrumb-size-screen-642-to-1366 {
      min-width: 1285px    !important;
  }
}


.coin-detail  .p-panel {
  padding: 0;
   background-color: transparent;
   border: 1px solid transparent; 
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.coin-detail .p-panel .p-panel-titlebar-icon {
  float: left;
  cursor: pointer;
  height: 0.7em;
  width: 0.7em;
  line-height: 0.7em;
  text-align: center;
}
.coin-detail .p-panel-titlebar-icon span {
line-height: inherit;
/* margin-top: -1px; */
}
.coin-detail .p-panel .p-panel-titlebar .p-panel-titlebar-icon {
position: relative;
width: 1.3em;
background-color: rgb(156, 143, 143);
height: 1.3em;
line-height: 1.3em;
text-align: center;
color: #6b6464;
margin: 0;
-moz-transition: background-color 0.3s;
-o-transition: background-color 0.3s;
-webkit-transition: background-color 0.3s;
transition: background-color 0.3s;
-moz-border-radius: 50%;
-webkit-border-radius: 50%;
border-radius: 50%;
}


.coin-detail .p-panel .p-panel-titlebar .p-panel-titlebar-icon:hover {
background-color:gray;
}
.coin-detail .p-panel .p-panel-titlebar-icon {
float: left;
cursor: pointer;
height: 0.7em;
width: 0.7em;
line-height: 0.7em;
text-align: center;
}

.coin-detail .p-panel .p-panel-titlebar {
  background-color: transparent;
  color: black;
  padding: 0em 0em;
  -moz-border-radius-topleft: 2px;
  -webkit-border-top-left-radius: 2px;
  border-top-left-radius: 2px;
  -moz-border-radius-topright: 2px;
  -webkit-border-top-right-radius: 2px;
  border-top-right-radius: 2px;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
}

.coin-detail .pi.pi-plus {
font-family: "Material Icons";
font-weight: normal;
font-style: normal;
font-size: 1.3em;
display: inline-block;
width: 1em;
height: 1em;
line-height: 1;
text-transform: none;
letter-spacing: normal;
word-wrap: normal;
white-space: nowrap;
direction: ltr;
text-indent: 0;
-webkit-font-smoothing: antialiased;
text-rendering: optimizeLegibility;
-moz-osx-font-smoothing: grayscale;
font-feature-settings: "liga";
}
.coin-detail .pi.pi-minus {
font-family: "Material Icons";
font-weight: normal;
font-style: normal;
font-size: 1.3em;
display: inline-block;
width: 1em;
height: 1em;
line-height: 1;
text-transform: none;
letter-spacing: normal;
word-wrap: normal;
white-space: nowrap;
direction: ltr;
text-indent: 0;
-webkit-font-smoothing: antialiased;
text-rendering: optimizeLegibility;
-moz-osx-font-smoothing: grayscale;
font-feature-settings: "liga";
}

.activity .tableData .p-datatable .p-datatable-tbody > tr > td {
background: inherit;
padding-top: 0.2em;
padding-right: 0em;
padding-bottom: 0.2em;
padding-left: 0.4em; 
border-left: 1px solid gray;
}


.activity .p-component, .p-component * {
box-sizing: border-box;
margin-bottom: 0px;
}




.activity .p-datatable .p-datatable-tbody > tr > td {
background: inherit;
padding: 0em 0em;
border: 0 none;
}


.droite {
  margin-left: 12px;
}
.coin {
  font-size: 12px;
  margin-top: -25px;
  margin-left: 35px;
  color: black;
  
}

.ContnairPanelFilter .filter .p-multiselect-panel .p-multiselect-header {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: #e4032e;
  color: #ffffff;
  border: 1px solid #e4032e;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  margin: 0;
  border: 0 none;
  padding: 0.714em 1em;
  position: relative;
}


.ContnairPanelFilter .filter .apply-btn {
  text-align: right;
}
.ContnairPanelFilter .filter .apply-btn button {
  max-width: 25%;
  background-color: #e4032e;
}
.ContnairPanelFilter .p-panel {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.ContnairPanelFilter .p-panel .p-panel-titlebar {
  background-color: #e4032e;
  color: #ffffff;
  border: 1px solid #e4032e;
  border: 0 none;
  padding: 0.714em 1em;
  -moz-border-radius-topleft: 2px;
  -webkit-border-top-left-radius: 2px;
  border-top-left-radius: 2px;
  -moz-border-radius-topright: 2px;
  -webkit-border-top-right-radius: 2px;
  border-top-right-radius: 2px;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ContnairPanelFilter .p-panel .p-panel-titlebar .pi {
  color: #ffffff;
}
.ContnairPanelFilter .p-panel .p-panel-titlebar .p-panel-title {
  margin: 0;
  line-height: 1.5;
}
.ContnairPanelFilter .p-panel .p-panel-titlebar .p-panel-titlebar-icon {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  text-align: center;
  color: #ffffff;
  margin: 0;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.ContnairPanelFilter .p-panel .p-panel-titlebar .p-panel-titlebar-icon:hover {
  background-color: #9fa8da;
}
.ContnairPanelFilter .p-panel .p-panel-content {
  height: 100%;
  box-sizing: border-box;
  padding: 0.857em 1em;
  line-height: 1.5;
}
.ContnairPanelFilter .p-panel .p-panel-footer {
  padding: 0.714em 1em;
  border: 0 none;
  border-top: 1px solid #bdbdbd;
  margin: 0;
}
.ContnairPanelFilter .p-dropdown-panel .p-dropdown-item.p-highlight {
  background-color: #e4032e;
  color: #ffffff;
}
body .p-checkbox-label {
margin:0 0.5em 0 0;
}
.ContnairPanelFilter  .p-checkbox .p-checkbox-box.p-highlight {
border-color: #e4032e;
background-color: #e4032e;
}
.ContnairPanelFilter .p-radiobutton .p-radiobutton-box.p-highlight {
border-color:  #e4032e;
background-color: transparent;
}


body .p-radiobutton .p-radiobutton-box.p-highlight {
border-color:#e4032e;
background-color: transparent;
}

body .p-radiobutton .p-radiobutton-box .pi-circle-on {
  background-color: #e4032e;
  -webkit-transform: scale(0.6);
  transform: scale(0.6);
  top: 50%;
  left: 50%;
  margin-top: -0.5em;
  margin-left: -0.5em;
}

span.error {
  color: transparent !important;
  background-color: transparent !important;
}

.error .pi-md-error {
  color: #e4032e;
  font-size: 3em;
}

.major_warning .pi-md-error {
  color: #f5972b;
  font-size: 3em;
}

.minor_warning .pi-md-error {
  color: #ffd700;
  font-size: 3em;
}

.info .pi-md-info {
  color: #1c46a1;
  font-size: 3em;
}

.info .pi-md-sync {
  color: #1c46a1;
  font-size: 3em;
}

.closed .pi-md-info,  .closed .pi-md-error, .closed .pi-md-warning {
  color: #30b853;
  font-size: 3em;
}
.typeDescriptionIcon{

    margin-left: -2px;
    margin-bottom: -5px;
}


.activity .p-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight {
  border-color: gray;
  color: #000000;
  border: gray;
}
.activity.p-datatable .p-datatable-tbody > tr:nth-child(even).p-contextmenu-selected {

  border-color: gray !important;
  border: gray !important;
  color: black;
}
.activity .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):not(.p-highlight-contextmenu):hover {
  cursor: pointer;
  color: #000000;
  border-color: gray;
  border: gray;
}
.activity .p-datatable-row
{
  border-color: gray;
  border: gray;
}
.activity .p-datatable .p-datatable-tbody > tr.p-highlight {
  background-color: #e4032e;
  color: #ffffff;
  border-color: gray;
  border: gray;
}
.activity .tableData .p-datatable .p-datatable-tbody > tr.p-highlight {
  background-color: #e2dde771;
  border-color: gray !important;
  border: gray !important;
  color: black;
  border: 2px solid #F49600 !important;
}

.activity  .p-paginator {
   background-color: #3F51B5; 
   color: #ffffff; 
  border: 1px solid #3F51B5; 
   background-color: #283593; 
  padding: 0.714em 1em;
}
body .p-datepicker table td > span.p-highlight {
  background-color: #e4032e;
  color: #ffffff;
}
body .p-datepicker table td.p-datepicker-today > span {
  color: #212121;
  background-color: #ffffff;
  border: 1px solid #e4032e;
}
.typeColumn {
  width: 55px !important;
  text-align: center !important;
  padding-left: 2px !important;
}
.implantColumn {
  width: 100px !important;
}

.dateColumn {

  width: 132px !important;
}
.p-link {
  outline: none !important;
}
.p-highlight {
  outline: none !important;
}

.equipment-opened-alarm { 
  text-align: center;
}
.equipment-opened-alarm-errors .count {
  text-align: center;
  font-size: 1.1em; 
  color: white;
  border-radius: 50%;
  background-color: #e4032e;
  width: 20px;
  height: 20px;
}

.equipment-opened-alarm-major .count {
  text-align: center;
  font-size: 1.1em; 
  color: white;
  border-radius: 50%;
  background-color: #f5972b;
  width: 20px;
  height: 20px;
}

.equipment-opened-alarm-mineur .count {
  text-align: center;
  font-size: 1.1em; 
  color: white;
  border-radius: 50%;
  background-color: #ffd700;
  width: 20px;
  height: 20px;
}

.activity .p-contextmenu {
  width: 255px;
}

.config-desc {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 15px !important;
  margin: 0 !important;
  font-style: italic;
}