.equipment-node .p-treetable-toggler {
    visibility: hidden !important;
}

.planning .note {
    font-size: 12px; font-weight: bold; color: grey;
}

.planning .calendar-label {
    text-align: right;
    padding-right: 20px !important;
}

.planning .plan-buttons {
    padding-top: 40px;
}

.planning .date-input {
    max-width: 100px;
}

.planning .time-input {
    max-width: 55px;
}

.firmwares .p-dropdown.p-disabled .p-dropdown-label {
    color: black;
}

.campaign-title h2, .campaign-title h3 {
    margin: 0;
}

.campaigns-list .p-datatable-row td {
    padding-left: 10px !important;
    padding-right: 0px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.campaigns-list .p-datatable-row td ul {
    padding-left: 10px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin: 0;
}
.campaigns-list .p-datatable-row td ul li {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}

.campaigns-list .progress li {
    padding-left: 5px;
    color: white;
}
.campaigns-list .progress .pending::marker { 
    color: #f49600;
}
.campaigns-list .progress .pending {
    background-color: #f49600;
}
.campaigns-list .progress .in_progress::marker {
    color: #4a96d2;
}
.campaigns-list .progress .in_progress {
    background-color: #4a96d2;
}

.campaigns-list .progress .acquitted::marker {
    color: #164194;
}
.campaigns-list .progress .acquitted {
    background-color: #164194;
}
.campaigns-list .progress .failed::marker {
    color: #e4032e;
}
.campaigns-list .progress .failed {
    background-color: #e4032e;
}
.campaigns-list .progress .succeed::marker {
    color: #00a655;
}
.campaigns-list .progress .succeed {
    background-color: #00a655;
}
.equipments-list .p-treetable .p-paginator {
    background-color: #F49600 !important;
    border: 1px solid #F49600 !important;
}
.equipments-list .p-treetable-thead tr th {
    color: #ffffff;
    background-color: #F49600 !important;
    border: 1px solid #F49600 !important;
}

/*.equipments-list .p-treetable .p-treetable-thead tr .p-inputtext {
    background-color:white !important;
}*/
.campaign-info {
    padding: 0 !important;
    padding-bottom: 11px !important;
    margin: 0 !important;
}

.campaign-info .info, .campaign-info .histo, .campaign-info .progress {
    padding: 0 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;     
}
.campaign-info .info {
    overflow-y: scroll;
}
.campaign-info .histo {
    overflow-y: scroll;
}

.campaign-info .campaign-title {
    color: #ffffff;
    background-color: #F49600 !important;
    padding: 5px !important;
    margin-bottom: 5px;
}

.campaign-chart .legend {
    margin-top: 2px;
    margin-left: 0px;
    padding-top: 10px !important;
    font-size: 12px;
    font-weight: bold;
}
.campaign-chart .legend .p-grid {
    padding: 0 !important;
    margin: 0;
}
.campaign-chart .legend .p-grid div {
    padding: 0 !important;
    margin: 0;
}

.infos {
    color: grey;
    padding-left: 10px;
}

.campaign-buttons {
    text-align: center !important;
}

.campaign-excel-btn {
    text-align: right !important;
    padding: 0 !important;
}

.st-placeholder .p-autocomplete input::placeholder {
    color:black !important;
    font-size: 1em;
    font-family: "Roboto", "Helvetica Neue", sans-serif;
}

.st-placeholder .p-autocomplete .p-disabled {
    color:black !important;
}

.equipments-list .p-treetable  td, .p-treetable  th {
    text-overflow: ellipsis;
    overflow: hidden;
}

.equipments-list .p-treetable .p-treetable-tbody > tr > td .p-treetable-checkbox {
    margin-right: 0.75em;
    margin-top: 2px;
    float: left;
}

.equipments-list .p-treetable-toggler {
    float: left;
}

.campaign .p-checkbox .p-disabled {
    background-color: #9e9e9e !important;
    border-color:  #757575 !important;
}

.campaign .p-disabled .p-dropdown-label {
    color:black !important;
}

tbody.p-treetable-tbody tr.equipment-node.p-highlight {
    border: 2px solid #F49600;
    background-color: rgba(226, 221, 231, 0.44313725490196076);
    color: black;
}