.gm-style .gm-style-iw {
    max-width: 340px !important;
}

.gm-iw-content {
    padding: 0 !important;
}

.gm-iw-st-details {
    text-align: left;
    font-weight: bold;
    font-size: 16px
}
.gm-iw-st-details span {
    padding: 1px;
}
.gm-iw-st-details .note {
    text-align: center;
    font-weight: normal;
    font-size: 13px;
    font-style: italic;
    margin-top: 5px;
}