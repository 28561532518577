/* Small devices (landscape phones, 576px and up)*/
@media only screen and (min-device-width: 576px) and (max-device-width: 767.98px) {
    .activity .prest .prest-icon span {
        font-size: 3.5em;
        width: 5px;
        margin-left: -50px;
    }
    
    
    .prest-amount .title h2 {
        margin-bottom: -5px;
        font-size: 18px;
        font-weight: bold;
        margin-top: 10px;
    }
    
    .prest-amount .amount h1 {
        font-weight: bold;
        margin-top: 0px;
        font-size: 20px;
        margin-bottom: 0px;
    }
    .activity .vente .vente-icon span {
        font-size: 3.5em;
        width: 5px;
        margin-left: -50px;
    }
    .activity-chart {   
        color: #000000;
        width: 500px;
    }
    
      .chart-title-prest {
        font-size: 13px;
        background-color: #00b752;
        padding: 4px;
        color: white;
        margin-left: 0px !important;
    
    }
    .chart-title-vente {
        font-size: 13px;
        background-color: rgb(75, 153, 211);
        padding: 4px;
        color: white;
        margin-left: 0px !important;
    
    }
    
    .vente-amount .title h2 {
        margin-bottom: -5px;
        font-size: 18px;
        font-weight: bold;
        margin-top: 10px;
    }
    
    .vente-amount .amount h1 {
        font-weight: bold;
        margin-top: 0px;
        font-size: 20px;
        margin-bottom: 0px;
    }
    
    
    .detail-container{
    	margin-top: 10px;
    	padding: 0 !important;
    }
    
    .detail{
        margin-left: -25px;
        font-size: 18px;
        margin-left: 1px;
    }
     .containerLegend {
        margin-left: -25px;    
    }
   
    .legend {
	    color:#696969;
	    list-style: none;
	    display: flex;
	    flex-direction: row;
	    font-size: 9.2px ;
	    font-style:  'bold';
	    padding: 15;        
    }
    .activity-analysis  {
        border-style: solid;
        border-color: #dadeea;
    }
    
    .activity-analysis .analysis-header {
        margin: auto;
        width: 95%;
        border-bottom-style: solid;
        border-bottom-color: #dadeea;
    }
    
    .analysis-header .header-title h2{
        font-size: 25px;
        font-weight: bold;
        margin-left: -17px;
    }
    .analysis-header .header-label {
        font-size: 18px;
    }
    .analysis-header .header-value {
        font-size: 18px;
        color: #00b752;
    }
    
    .analysis-header .amount h1 {
        color: white;
        font-weight: bold;
        text-align: center;
        background-color:  rgb(75, 153, 211);
        padding: 5px;
    }
    
    .activity-analysis .content-header {
        margin: auto;
        width: 95%;
        font-size: 18px;
    }
    .activity-analysis .content-header .cheader-label {
        
        margin-left: -26px;
        width: 193px;
    }
    .activity-analysis .content-header .cheader-value {
        margin-left: 73px;
        font-size: 22px;
        font-weight: bold;
    }
    
    .content-header .average {
        color: white;
        background-color:  rgb(75, 153, 211);
        padding: 2px 10px;
        margin-left: -100px;
    }
    
    .activity-analysis .prest .prest-icon {
        text-align: center;
        padding-top: 10px;
    }
    
    .activity-analysis .borne .borne-icon, .activity-analysis .gantry .gantry-icon {
        text-align: center;
        padding-top: 5px;
    }
    
    .activity-analysis .prest .prest-icon span {
        color: rgb(0, 183, 82);
        font-size: 5em;
    }
    
    .activity-analysis .borne .borne-icon span {
        color: #e4032e;
        font-size: 5em;
    }
    
    .activity-analysis .gantry .gantry-icon span {
        color: rgb(14, 65, 148);
        font-size: 5em;
    }
    
    .activity-analysis .prest .prest-label {
        padding-top: 25px;
    }
    
    .activity-analysis .borne .borne-label, .activity-analysis .gantry .gantry-label {
        padding-top: 10px;
    }
    
    .activity-analysis .prest .prest-label h2, .activity-analysis .borne .borne-label h2,
   	.activity-analysis .gantry .gantry-label h2 {
        font-size: 25px;
        font-weight: bold;
    }
    
    .activity-analysis .prest .prest-amount {
        text-align: right;
        padding-top: 35px;
    }
    
    .activity-analysis .borne .borne-amount, .activity-analysis .gantry .gantry-amount {
        text-align: right;
        padding-top: 20px;
    }
    
    .activity-analysis .prest-amount .amount h1 {
        font-weight: bold;
        margin-top: 0px;
        margin-bottom: 0px;
        background-color: rgb(0, 183, 82);
    }
    
    .activity-analysis .borne-amount .amount h1 {
        font-weight: bold;
        margin-top: 0px;
        margin-bottom: 0px;
        background-color: #e4032e;
    }
    
    .activity-analysis .gantry-amount .amount h1 {
        font-weight: bold;
        margin-top: 0px;
        margin-bottom: 0px;
        background-color: rgb(14, 65, 148);
    }
    
    .activity-analysis .prest-amount .amount {
        font-weight: bold;
        color: white;
        background-color: rgb(0, 183, 82);
        text-align: center;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    
    .activity-analysis .borne-amount .amount {
        font-weight: bold;
        color: white;
        background-color: #e4032e;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    
    .activity-analysis .gantry-amount .amount {
        font-weight: bold;
        color: white;
        background-color: rgb(14, 65, 148);
        text-align: center;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    
    .activity-analysis .vente .vente-icon {
        text-align: center;
        padding-top: 10px;
    }
    
    .activity-analysis .vente .vente-icon span {
        color: rgb(14, 65, 148);
        font-size: 5em;
    }
    
    .activity-analysis .vente .vente-label {
        padding-top: 20px;
    }
    
    .activity-analysis .vente .vente-label h2 {
        font-size: 25px;
        font-weight: bold;
    }
    
    .activity-analysis .vente .vente-amount {
        text-align: right;
        padding-top: 35px;
    }
    
    .activity-analysis .vente-amount .amount h1 {
        font-weight: bold;
        margin-top: 0px;
        margin-bottom: 0px;
        background-color: rgb(14, 65, 148);
    }
    
    .activity-analysis .vente-amount .amount {
        font-weight: bold;
        color: white;
        background-color: rgb(14, 65, 148);
        text-align: center;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    
    .activity-analysis-chart {
        color: #000000;
    }
    .dashboard .p-panel .p-panel-titlebar .p-panel-titlebar-icon {
        position: relative;
        width: 1.5em;
        height: 1.5em;
        line-height: 1.5em;
        text-align: center;
        color: #ffffff;
        margin: 0;
        -moz-transition: background-color 0.3s;
        -o-transition: background-color 0.3s;
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background-color: grey;
       
      }
      .activityPrestation .p-panel .p-panel-titlebar .p-panel-titlebar-icon {
        margin-top: -98px;
    }
    .activityVente .p-panel .p-panel-titlebar .p-panel-titlebar-icon {
        margin-top: -98px;
    }
    .activity .p-panel-titlebar .pi.pi-minus {
        
        font-size: 0.9em; 
        display: inline-block;
        width: 1em;
        height: 1em;
        line-height: 1.58;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        text-indent: 0;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: "liga";
    }
    .activity .p-panel-titlebar .pi.pi-plus {
        
        font-size: 0.9em; 
        display: inline-block;
        width: 1em;
        height: 1em;
        line-height: 1.58;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        text-indent: 0;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: "liga";
    }
    .vente-icon .imageActivity {
        width: 43px;
    } 
    
    .maint-status .refresh .refresh-time .time {
    	margin-left: -15px;
        font-size: 18px;
    }
    
}