.report .export-button {
    text-align: center;
}

.report .export-button button {
    border-radius: unset;
    box-shadow: unset;
    width: 55px;
    height: 55px;
    padding: 0;
    color: #3F51B5;
    background-color: #e9f6ff;
}

.report .pi-md-euro-symbol {
    font-size: 3.5em;
}

.report .filter .apply-btn {
    text-align: right;
}

.report .filter .reset-btn {
  text-align: left;
}

.report .filter .from-date, .filter .to-date{
    text-align: left;
}

.buttonSelected {
    background-color: #e9f5ff  !important;
    border-radius: 10px!important;
    border: 2px solid #214396 !important;
  }
  
  .buttonNotSelected{
    color: #214396 !important;
    background-color: transparent!important;
    border-radius: 10px;
    border: 2px solid transparent !important;
  }

  .export-button .pi-md-account-balance, .export-button .pi-md-account-balance-wallet,
  .export-button .pi-md-sync, .export-button .pi-md-rotate-90-degrees-ccw {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 4.4em;
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
  }

  .export-button a {
    color: #214396 !important;
    text-decoration: none;
  }

  .export-button .payments {
    margin-bottom: -5px;
    width: 85px;
    height: 75px;
    padding: 5px;
  }

  .export-button .transactions {
    margin-bottom: -5px;
    width: 85px;
    height: 75px;
    padding: 0.5px;
  }

  .export-button .closure {
    margin-bottom: -5px;
    width: 85px;
    height: 75px;
    padding: 5.5px;
  }

  .export-button .mandat {
    margin-bottom: -5px;
    width: 85px;
    height: 75px;
    padding: 5px;
  }

  .report .filter .p-autocomplete {
    width: 33.3333% !important;
  }