.system-Events .filter .apply-btn {
    text-align: right;
}
.system-Events .filter .apply-btn button {
    max-width: 25%;
    background-color: #e4032e;
}
.system-Events .p-panel {
    padding: 0;
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }
  .system-Events .p-panel .p-panel-titlebar {
    background-color: #e4032e;
    color: #ffffff;
    border: 1px solid #e4032e;
    border: 0 none;
    padding: 0.714em 1em;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .system-Events .p-panel .p-panel-titlebar .pi {
    color: #ffffff;
  }
  .system-Events .p-panel .p-panel-titlebar .p-panel-title {
    margin: 0;
    line-height: 1.5;
  }
  .system-Events .p-panel .p-panel-titlebar .p-panel-titlebar-icon {
    position: relative;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    text-align: center;
    color: #ffffff;
    margin: 0;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
  .system-Events .p-panel .p-panel-titlebar .p-panel-titlebar-icon:hover {
    background-color: #9fa8da;
  }
  .system-Events .p-panel .p-panel-content {
    height: 100%;
    box-sizing: border-box;
    padding: 0.857em 1em;
    line-height: 1.5;
  }
  .system-Events .p-panel .p-panel-footer {
    padding: 0.714em 1em;
    border: 0 none;
    border-top: 1px solid #bdbdbd;
    margin: 0;
  }
  .system-Events .p-dropdown-panel .p-dropdown-item.p-highlight {
    background-color: #e4032e;
    color: #ffffff;
}