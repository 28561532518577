.filter .apply-btn {
    text-align: right;
}
.filter .apply-btn button, .filter .reset-btn button {
    background-color: #e4032e;
    width: 100px;
}
.filter .title {
    text-align: center;
}
.filter .from-date, .filter .to-date{
    text-align: right;
}
.filter .p-autocomplete, .filter .p-autocomplete .p-inputtext,
.filter .p-autocomplete .p-autocomplete-input-token, .filter .p-autocomplete .p-autocomplete-input-token input {
    width: 100% !important;
    padding: 3.5px 3.5px 3.5px 6px;
}
.filter .p-dropdown-panel .p-dropdown-item.p-highlight {
    background-color: #e4032e;
    color: #ffffff;
}
.filter .p-dropdown {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border-width: 0;
    background: transparent no-repeat;
    background-image: linear-gradient(to bottom,  #e4032e,  #e4032e), linear-gradient(to bottom, #bdbdbd, #bdbdbd);
    background-size: 0 2px, 100% 1px;
    background-position: 50% 100%, 50% 100%;
    transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
    padding-bottom: 2px;
    box-sizing: border-box;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}
.filter .st-placeholder .p-autocomplete input::placeholder {
    color:black !important;
    font-size: 1em;
    font-family: "Roboto", "Helvetica Neue", sans-serif;
}
.filter .p-autocomplete button {    
    margin-bottom: 8px;
}
.filter .p-autocomplete ul {
    padding: 0;   
}
span#stations {
    padding-left: 1px;
    padding-right: 1px;
}