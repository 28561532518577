
.activity  {
    border-style: solid;
    border-color: #dadeea;
}


.activity .p-fieldset-legend {
    padding-bottom: 0 !important;
}

.activity .p-fieldset-legend a {
    padding: 0 !important;
}

.activity .p-fieldset-legend-text {
    font-size: large;
    color: black !important;
    font-weight: bold;
}

.activity .p-fieldset-toggler, .activity .p-fieldset-toggler:hover {
    background-color: grey !important;
    width: 1.5em !important;
    height: 1.5em !important;
    padding: 0.26em !important;
}
.p-panel .p-panel-titlebar-icon span  {
    margin-top: 0px !important;
}

.activity .period {
    height: 55px;
    margin-left: 0px;
    margin-top: -15px;
}

.activity .period .period-refresh {
    text-align: right;
}

.activity .period-refresh .p-button {
    background-color: grey !important;
}

.activity .prest {
    color: white;
    margin-top: 0.5px;
    margin-left: 0;
    margin-rigt: 0;
    padding-left: 0;
    padding-right: 0;
}
body .p-fieldset {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: .2em;
}

.activity .prest .prest-icon {
    background-color: rgb(0, 166, 85);
    text-align: center;
    padding-top: 12.5px;
}

.activity .prest .prest-amount {
    background-color: rgb(0, 183, 82);
    text-align: center;
}

.activity .prest .prest-arrow {
    background-color: rgb(0, 183, 82); 
    text-align: right
}

.activity .vente {
    color: white;
    margin-top: 10px;
    margin-left: 0;
    margin-rigt: 0;
    padding-left: 0;
    padding-right: 0;
}

.activity .vente .vente-icon {
    background-color: rgb(14, 65, 148);
    text-align: center;
    padding-top: 14px;
}

.activity .vente .vente-amount {
    background-color: rgb(75, 153, 211);
    text-align: center;
}

.vente-amount .title h2 {
    margin-bottom: -5px;
    font-size: 18px;
    font-weight: bold;
    margin-top: 5px;
}

.activity .vente .vente-arrow {
    background-color: rgb(75, 153, 211); 
    text-align: right
}

.panel-prest{
    padding: 0em 0em;
    margin-top: 10px;
}

.dashboard .p-panel {
    padding: 0;
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }
.dashboard .p-panel .p-panel-titlebar {
    background-color:white;
    color: #ffffff;
    border: 1px solid #3F51B5;
    border: 0 none;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
     margin: 0;
    padding: 0;
  }
  .dashboard .p-panel .p-panel-titlebar .pi {
    color: #ffffff;
  }
  .dashboard .p-panel .p-panel-titlebar .p-panel-title {
    margin: 0;
    line-height: 1.5;
    margin: 0;
    padding: 0;
  }
 
  .dashboard .p-panel .p-panel-content {
    height: 100%;
    box-sizing: border-box;
    padding: 0;
    line-height: 1.5;
  }
  .dashboard .p-panel .p-panel-footer {
    padding: 0.714em 1em;
    border: 0 none;
    border-top: 1px solid #bdbdbd;
    margin: 0;
  }
.dashboard .p-datagrid .p-panel .p-panel-titlebar {
    background-color: #ffffff;
    color: #212121;
  }

.dashboard .p-panel .p-panel-titlebar .p-panel-titlebar-icon:hover {
    background-color: gray;

}
body .p-datepicker table td > span.p-highlight {
    background-color: #e4032e;
    color: #ffffff;
}
body .p-datepicker table td.p-datepicker-today > span {
    color: #212121;
    background-color: #ffffff;
    border: 1px solid #e4032e;
}

.pi.pi-minus {
    font-size: 1em !important;
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1.2;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
}
.pi.pi-plus {
    font-size: 1em !important;
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.2;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
}

body .p-fieldset .p-fieldset-legend .p-fieldset-legend-text {
    color: #3F51B5;
}
body .p-fieldset .p-fieldset-legend .p-fieldset-toggler {
    width: 1em;
    height: 1em;
    padding: 0.001em;
    background-color: gray;
    color: white;
    font-size: 1.5em;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    margin-top: -5px;
    margin-right: 8px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    /* -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12); */
    -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    /* position: relative; */
    /* overflow: hidden; */
}
.p-fieldset-legend .pi.pi-plus {
   
    font-size: 0.9em !important;
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1.2;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
}

.exploit {
    margin: 0 !important;
}
.exploit .global-header {
    padding: 0px; margin: 0px; border: medium none;
}
.exploit .global-header .out-mandate-icon {
    background-color: rgb(14, 65, 148);
    text-align: center;
}
.exploit .global-header .out-mandate-icon img {
    width: 60px;
    height: 60px;
    margin-top: 20px;
    margin-left: -8px;
}
.exploit .global-header .out-mandate-amount {
    font-size: 23px; font-weight: bold; color: white; 
    background-color: rgb(75, 153, 211); 
    text-align: center;
    margin: 0;
}
.exploit .global-header .com-mandate-icon {
    background-color: #c47900;
    text-align: center;
}
.exploit .global-header .com-mandate-icon .pi-md-rotate-90-degrees-ccw {
    font-size: 5em; color: white; margin-top: 14px; margin-left: -8px;
}
.exploit .global-header .com-mandate-amount {
    font-size: 23px; font-weight: bold; color: white;
    background-color: #f49600;
    text-align: center; 
    margin: 0;
}

.activity-analysis .analysis-header {
	height: 150px;
}

.maint-dashboard .activity-analysis .analysis-header {
	height: 100px;
}

.activity-analysis .sub-header {
    padding-left: 0;
    padding-right: 0;
    font-size: 16px;
}
.activity-analysis .sub-header .label {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
}
.activity-analysis .sub-header .amount {
    padding-top: 0;
    padding-bottom: 0;
    text-align: right;
}
.chart-labels-legend {
    margin-right: 3px;
    width: 14px;
    height: 14px;
    border-radius: 20px;
    padding: 7px;
    white-space: nowrap
}
.labels-legend-item {
    font-weight: bold;
    white-space: nowrap;
}

.maint-status  {
    border-style: solid;
    border-color: #dadeea;
}

.maint-status .p-fieldset-legend {
    padding-bottom: 0 !important;
}

.maint-status .p-fieldset-legend a {
    padding: 0 !important;
}

.maint-status .p-fieldset-legend-text {
    font-size: large;
    color: black !important;
    font-weight: bold;
}

.maint-status .p-fieldset-toggler, .maint-status .p-fieldset-toggler:hover {
    background-color: grey !important;
    width: 1.5em !important;
    height: 1.5em !important;
    padding: 0.26em !important;
}

.maint-status .refresh {
    min-height: 0px;
    margin-left: 10px;
    margin-top: -15px;
}

.maint-status .refresh .refresh-time {
	margin-top: 16px;
    margin-left: -8px;
}

.maint-status .refresh .refresh-button {
    text-align: right;
}

.maint-status .refresh-button .p-button {
    background-color: grey !important;
}

.maint-status .borne-hs {
    background-color: #e4032f;
    padding: 0;
}

.maint-status .borne-hs .borne-icone {
    background-color: #b10326;
}

.maint-status .borne-degraded {
    background-color: #f49600;
    padding: 0;
}

.maint-status .borne-degraded .borne-icone {
    background-color: #965e01;
}

.maint-status .borne-ok {
    background-color: #84cd64;
    padding: 0;
}

.maint-status .borne-ok .borne-icone {
    background-color: #2f8a07;
}

.maint-status .borne-icone {
    font-size: xxx-large !important;
    margin-top: 0px !important;
}

.maint-status .pi-md-dock {
    margin-top: 15px !important;
}

.maint-status .borne-info .label {
    padding-bottom: 0;
}

.maint-status .borne-info .value {
    padding-top: 0;
}

.maint-status .borne-info h2 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 30px;
}

.maint-status .borne-info h1 {
    font-size: 25px;
    font-weight: bold;
    margin-top: 5px;
}

.maint-status .borne-info a {
    color: white;
}

.maint-status .borne-icone, .maint-status .borne-info {
    color: white;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}

.maint-status .p-fieldset {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: 0.2em 0.2em;
}

.maint-status .p-fieldset .p-fieldset-legend .p-fieldset-toggler {
    width: 2em;
    height: 2em;
    padding: 0.5em;
    background-color: gray;
    color: white;
    font-size: 1.5em;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    margin-top: -12px;
    margin-right: 8px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.maint-status .p-fieldset-legend .pi.pi-plus {
    font-size: 0.9em !important;
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1.2;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
}

.maint-status .dashboard-panel {
    padding-left: 15px;
}

.activity-analysis #imageActivity {
	margin-top: 10px;
	width: 60px;
	height: 60px;
	margin-left: -8px;
}

.activityPrestation , .activityVente {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
}

.activity-chart .analyse {
    padding: 0;
}

.dashboard .activity-data {
    padding: 0;
    margin: 0;
}